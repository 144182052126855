.coming-soon-container {
  display: flex;
  height: 100vh;
  font-family: "Arial", sans-serif;
}

.left-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #0db6c3;
}

.coming-soon-title {
  display: flex;
  font-family: "Gill Sans Extrabold", sans-serif;
  font-size: 48px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #021526;
}

.coming-soon-subtitle {
  font-family: "Gill Sans Extrabold", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #021526;
}

.social-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.social-icons a {
  font-family: "Gill Sans Extrabold", sans-serif;
  font-weight: 600;
  color: #021526;
}

.social-icon {
  margin: 10px 0;
  color: #333;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icon i {
  margin-right: 10px;
}

.social-icon:hover {
  color: #777;
}

.right-section {
  width: 50%;
  background-color: #021526;
}

.right-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Mobile View */
@media (max-width: 768px) {
  .coming-soon-container {
    flex-direction: column;
  }

  .left-section,
  .right-section {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .coming-soon-title {
    font-size: 32px;
  }

  .coming-soon-subtitle {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .email-input-container {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
  }

  .email-input,
  .submit-button {
    width: auto;
  }

  .email-input {
    margin-right: 5px;
    border-radius: 5px;
  }

  .submit-button {
    border-radius: 5px;
  }

  .social-icons {
    align-items: center;
    margin-top: 15px;
  }
}
